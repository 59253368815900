import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Layout, Container } from '../components/common'
import SEO from '../components/common/SEO'
import Header from '../components/theme/Header'
import Footer from '../components/theme/Footer'

const MainContentHolder = styled.div`

`

const NotFoundPage = () => (
	<Layout>
		<React.Fragment>
			<SEO title="not_found" />
			<Header />
			<MainContentHolder className="main-content-holder">
				<Container>
					<h2>
						<FormattedMessage id="not_found" />
					</h2>
				</Container>
			</MainContentHolder>
			<Footer />
		</React.Fragment>
	</Layout>
)

export default NotFoundPage
